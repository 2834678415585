import { Box } from '@mui/material';
import React from 'react';
import ASTITitle from '../../labels/ASTITitle';
import ASTIText from '../../labels/ASTIText';

export default function About() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={4}
    >
      <ASTITitle text="About" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        gap={2}
      >
        <ASTIText text="Website designed by ChebManu" />
        <ASTIText text="Developed up pro bono in 5 minutes, between two guitar solos of Lynyrd Skynyrd - Free bird, by Kevin Hang" />
        <ASTIText text="Brought to life and online by grand master Mischa Molhoek" />
        <ASTIText text="Contact and/or book us !! Follow us and send us a DM on Instagram -> @astir.oids" />
      </Box>
    </Box>
  );
}

import { Box } from '@mui/material';
import React from 'react';
import ASTITitle from '../../labels/ASTITitle';
import ASTIText from '../../labels/ASTIText';
import ASTILink from '../../labels/ASTILink';

export default function Shows() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={4}
    >
      <ASTITitle text="Upcoming Dates" />
      
      <ASTIText text="Contact and/or book us !! Follow us and send us a DM on Instagram -> @astir.oids" />
      
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="80%"
        gap={2}
      >
        <ASTIText text="Jam with Humans & ASTIRoids - Spark Rotterdam - 30 Jan 25" />
      </Box>

      <ASTITitle text="Past Dates" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="80%"
        gap={2}
      >
        <ASTIText text="Jam with Humans & ASTIRoids - Ruby Hotel - 19 Dec 24" />
        <ASTIText text="ASTIRoids - Bar BaggerBeest - 14th of December 24" />
        <ASTIText text="Jam with Humans mini Fest - The Parlour - 8th of December 24" />
        <ASTIText text="Lazer Lazer - OT301 - Ventilator Cinema - 6th of December 24" />
        <ASTIText text="Jam with Humans – The Parlour – 3rd of November 2024" />
        <ASTIText text="Land of Giants – Easylaughs - CREA Muziekzaal – 1st of November 2024" />
        <ASTIText text="Jam with Humans – The Parlour – 22nd of September 2024" />
        <ASTIText text="2-year Anniversary Show – OT301/Ventilator Cinema – 14th of September 2024" />
        <ASTIText text="Jam with Humans – The Parlour – 8th of September 2024" />
        <ASTIText text="Jam with Humans – Q-Factory – 1st of June 2024" />
        <ASTIText text="Tag-Out Theater – Mike's Badhuistheater – 2nd of May 2024" />
        <ASTIText text="Jam with Humans – Q-Factory – 24th of March 2024" />
        <ASTIText text="Jam with Humans – Q-Factory – 7th of November 2023" />
        <ASTIText text="Jam with Humans – Q-Factory – 13th of October 2023" />
        <ASTIText text="Tag-Out Theater – Mike's Badhuistheater – 12th of October 2023" />
        <ASTIText text="Tag-Out Theater – Mike's Badhuistheater – 7th of September 2023" />
        <ASTIText text="Boom Chicago Comedy Festival – Boom Chicago – 16th of July 2023" />
        <ASTIText text="Easylaughs – CREA Muziekzaal - 2nd of June 2023"  />
        <ASTIText text="Tag-Out Theater – Mike's Badhuistheater – 18th of May 2023" />
        <ASTIText text="Tag-Out Theater – Mike's Badhuistheater – 30th of March 2023" />
        <ASTIText text="Tag-Out Theater – Mike's Badhuistheater – 16th of February 2023" />















        <ASTIText text="Boom Chicago Comedy Festival – Boom Chicago – 17th of July 2022" />
        
      </Box>

    </Box>
  );
}

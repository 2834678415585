import PropTypes from 'prop-types';

import {
  SECTION_ABOUT,
  SECTION_BigBang,
  SECTION_MEMBERS,
  SECTION_MERCHANDISE,
  SECTION_RESOURCES,
  SECTION_SHOWS,
  SECTION_TEACHERS,
} from '../../constants/sections';
import ASTIButton from '../buttons/ASTIButton';

function ASTINavbarButtons(props) {
  const { changeSection, section } = props;
  
  return (
    <>
      <ASTIButton
        text="BigBang"
        onClick={() => changeSection(SECTION_BigBang)}
        selected={section === SECTION_BigBang}
      />
      <ASTIButton
        text="Shows"
        onClick={() => changeSection(SECTION_SHOWS)}
        selected={section === SECTION_SHOWS}
      />
      <ASTIButton
        text="Members"
        onClick={() => changeSection(SECTION_MEMBERS)}
        selected={section === SECTION_MEMBERS}
      />
      <ASTIButton
        text="Teachers"
        onClick={() => changeSection(SECTION_TEACHERS)}
        selected={section === SECTION_TEACHERS}
      />
      <ASTIButton
        text="Merchandise"
        onClick={() => changeSection(SECTION_MERCHANDISE)}
        selected={section === SECTION_MERCHANDISE}
      />
      <ASTIButton
        text="Resources"
        onClick={() => changeSection(SECTION_RESOURCES)}
        selected={section === SECTION_RESOURCES}
      />
      <ASTIButton
        text="About"
        onClick={() => changeSection(SECTION_ABOUT)}
        selected={section === SECTION_ABOUT}
      />
    </>
  );
}

export default ASTINavbarButtons;

ASTINavbarButtons.propTypes = {
  section: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
};

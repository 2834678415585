import React, { useState } from 'react';
import { Box } from '@mui/material';

import ASTINavBar from '../navbar/ASTINavbar';
import ASTIHeader from '../labels/ASTIHeader';
import ASTIDashboardBody from './ASTIDashboardBody';
import ASTINavBarMobile from '../navbar/ASTINavbarMobile';
import AstiroidsLogo from '../../assets/images/astiroids_logo.png';

export default function ASTIDashboard() {
  const [section, setSection] = useState('default');

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      position="relative"
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'row', sm: 'column' }}
        justifyContent="center"
        alignItems="center"
        py={4}
        width="100%"
        position="fixed"
        top={0}
        sx={{
          backgroundColor: 'black',
          borderBottom: '1px solid gray',
          zIndex: 1200,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        {/* Mobile navbar */}
        <ASTINavBarMobile section={section} setSection={setSection} />

        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          <img src={AstiroidsLogo} alt="astiroids_logo" width="5%" />
          <ASTIHeader text="ASTIRoids" />
        </Box>

        {/* Desktop navbar */}
        <ASTINavBar section={section} setSection={setSection} />
      </Box>
      <Box
        pt={{ xs: 20, sm: 37.5 }} // Highlight: Extra top padding for smaller screens
        pb={2}
      >
        <ASTIDashboardBody section={section} />
      </Box>
    </Box>
  );
}

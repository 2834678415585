import { Box } from '@mui/material';
import React from 'react';
import ASTITitle from '../../labels/ASTITitle';
import ASTIText from '../../labels/ASTIText';
import Hoodie from '../../../assets/images/hoodie.jpeg';
import TShirtHerenSC from '../../../assets/images/tshirtheren.png';
import HoodieHerenSC from '../../../assets/images/hoodieheren.png';


export default function Merchandise() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={4}
    >
      <ASTITitle text="Merchandise" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        gap={2}
      >
        <ASTIText text="Hoodies" />
        <ASTIText text="We can print for you some hoodies (also zip-up if you insist) or T-shirts with the ASTIRoids logo." />
        <img src={Hoodie} alt="astiroids_hoodie" width="40%" />'
        <ASTIText text="We work with the website shirttuning.nl, check out their size chart below." />
        <img src={TShirtHerenSC} alt="TShirt_Male_Size_Chart" width="40%" />'
        <img src={HoodieHerenSC} alt="Hoodie_Male_Size_Chart" width="40%" />'

        <ASTIText text="Contact and/or book us !! Follow us and send us a DM on Instagram -> @astir.oids" />
      </Box>
    </Box>
  );
}

import { useEffect } from 'react';

/**
 * This hook calls the callback function when it detects clicks outside of the element ref
 * @param {ref} ref reference to the dropdown menu
 * @param {func} callback function to call when the click is outside of the ref
 * @param {boolean} shouldAddEventListener 
 * @param {ref} excludeRef reference to exclude (e.g. the ... button)
 */
export const useClickOutside = (ref, callback, shouldAddEventListener, excludeRef) => {
  
  useEffect(() => {
    const handleClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !excludeRef.current.contains(event.target)
      ) {
        callback();
      }
    };

    if (shouldAddEventListener) {
      document.addEventListener('mouseup', handleClick);
    }

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAddEventListener]);
};

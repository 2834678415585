import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import ASTINavbarButtons from './ASTINavbarButtons';
import ASTIButton from '../buttons/ASTIButton';
import { useClickOutside } from '../../hooks/useClickOutside';

export default function ASTINavBarMobile(props) {
  const { section, setSection } = props;
  const [openNavBar, setOpenNavBar] = useState(false);
  const openNavBarRef = useRef();
  const navBarRef = useRef();

  const closeNavBar = () => {
    setOpenNavBar(false);
  };

  useClickOutside(navBarRef, closeNavBar, openNavBar, openNavBarRef);

  const handleClickOpenNavBar = () => {
    setOpenNavBar(!openNavBar);
  };

  const handleChangeSection = (newSection) => {
    setSection(newSection);
    setOpenNavBar(false);
  };

  return (
    <Box display={{ xs: 'flex', sm: 'none' }} position="fixed" left={2}>
      <ASTIButton
        text="..."
        onClick={handleClickOpenNavBar}
        selected={openNavBar}
        buttonRef={openNavBarRef}
      />
      {openNavBar && (
        <Box
          position="fixed"
          left={0}
          top={60}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          overflow="auto"
          width="100%"
          border="solid 1px gray"
          borderRadius="3px"
          bgcolor="black"
          ref={navBarRef}
        >
          <ASTINavbarButtons
            section={section}
            changeSection={handleChangeSection}
          />
        </Box>
      )}
    </Box>
  );
}

ASTINavBarMobile.propTypes = {
  section: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import ASTINavbarButtons from './ASTINavbarButtons';

export default function ASTINavBar(props) {
  const { section, setSection } = props;

  const changeSection = (id) => {
    setSection(id);
  };

  return (
    <Box
      display={{ xs: 'none', sm: 'flex' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="center"
      alignItems="center"
      // Overflow was set to scroll by Kevin, I modified to auto
      overflow="auto"
      width="100%"
    >
      <ASTINavbarButtons changeSection={changeSection} section={section} />
    </Box>
  );
}

ASTINavBar.propTypes = {
  section: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
};
